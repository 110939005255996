import React from 'react'

const UsuarioContext = React.createContext(undefined);
const UsuarioDispatchContext = React.createContext(undefined);

function UsuarioProvider({ children }) {
    const [usuario, setUsuario] = React.useState();
    return (
        <UsuarioContext.Provider value={usuario}>
            <UsuarioDispatchContext.Provider value={setUsuario}>
                {children}
            </UsuarioDispatchContext.Provider>
        </UsuarioContext.Provider>
    )
}

export { UsuarioProvider, UsuarioContext, UsuarioDispatchContext }