// This may not be needed
// class PrivateAlmacenAPI {
//     constructor() {
//         console.log('API Singleton Instantiated.');
//     }
// }
// Libraries
// const { v4: uuidv4 } = require("uuid");
// const Cookies = require("universal-cookie");

// Configuration Constants
// const API_TOKEN_HEADER_KEY = "Handygolf-Api-Token";
// const API_TOKEN_COOKIE_NAME = "HGAPI-token";
// const cookies = new Cookies();

class AlmacenAPI {
    constructor() {
        throw new Error("Cannot be used in instantiated form.");
    }

    static AUTH_TYPE_EMAIL = 1;
    static AUTH_TYPE_GOOGLE = 2;
    static AUTH_TYPE_APPLE = 3;

    static async configure() {
        if (!AlmacenAPI.configured) {
            // DEV
            AlmacenAPI.hostname = "http://localhost:1234/api/"
            // PROD
            // AlmacenAPI.hostname = "/api/"
            if (!AlmacenAPI.hostname) {
                throw new Error("API Hostname is undefined.");
            }
            AlmacenAPI.configured = true;
        }
        // await AlmacenAPI.checkToken();
    }

    static async sendRequest(method, params) {
        await AlmacenAPI.configure();
        let headers = new Headers({
            // [API_TOKEN_HEADER_KEY]: AlmacenAPI.api_token,
            "Content-Type": "application/json; charset=utf-8",
        });
        return fetch(AlmacenAPI.hostname + method, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(params),
        })
            .then((response) => response.json())
            .then((data) => {
                // if (!data.status || data.status === "error") {
                //     alert(data.message);
                //     // throw new Error(data.message || "An unknown error occurred.")
                // }
                return data;
            });
    }

    // static api_token = "";
    // static session = false;
    // static async checkToken(reset_cache = false) {
    //     // Cache Token (if not already cached)
    //     if (!AlmacenAPI.api_token) {
    //         AlmacenAPI.api_token = cookies.get(API_TOKEN_COOKIE_NAME);
    //     }
    //     // Check for Cached Session
    //     if (AlmacenAPI.session && !reset_cache) {
    //         return true;
    //     }
    //     // Check for Empty Token
    //     if (!AlmacenAPI.api_token) {
    //         if (AlmacenAPI.session) {
    //             AlmacenAPI.session = false;
    //         }
    //         return false;
    //     }
    //     // Retrieve Session (should only happen if token exists and session has not been pulled yet or reset_cache was passed in as true)
    //     let headers = new Headers({
    //         [API_TOKEN_HEADER_KEY]: AlmacenAPI.api_token,
    //         "Content-Type": "application/json; charset=utf-8",
    //     });
    //     const response = await fetch(
    //         HandyGolf.getValue("api_hostname") + "/api/user/checkToken",
    //         {
    //             method: "POST",
    //             headers: headers,
    //             body: JSON.stringify({}),
    //         }
    //     )
    //         .then((response) => response.json())
    //         .then((data) => {
    //             if (!data.status || data.status === "error") {
    //                 alert(data.message);
    //                 // throw new Error(data.message || "An unknown error occurred.")
    //             }
    //             return data.response;
    //         });
    //     // Store Session
    //     AlmacenAPI.session = response.session;
    //     // Check for new Token
    //     AlmacenAPI.setApiToken(response.token);
    // }
    //
    // static setApiToken(api_token) {
    //     AlmacenAPI.api_token = api_token;
    //     const date = new Date();
    //     const expire_time = date.getTime() + 90 * 86400 * 1000; // 90 days from now
    //     date.setTime(expire_time);
    //     const cookie_options = { path: "/", expires: date };
    //     cookies.set(API_TOKEN_COOKIE_NAME, AlmacenAPI.api_token, cookie_options);
    // }
    //
    // static resetSession() {
    //     AlmacenAPI.session = false;
    //     AlmacenAPI.setApiToken("");
    // }
    //
    // static async getSession(reset_cache = false) {
    //     //commented out these 3 console.logs on may 30, -nate
    //     // console.log('Getting Session...');
    //     let token_response = await AlmacenAPI.checkToken(reset_cache);
    //     // console.log('Check Token Response:')
    //     // console.log(token_response);
    //     return AlmacenAPI.session;
    // }
    //
    // static debugAPI() {
    //     AlmacenAPI.configure();
    //     // @todo Build Headers with Authentication
    //     return fetch(AlmacenAPI.hostname + "/api/debug", {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json; charset=utf-8",
    //         }),
    //     });
    // }

    // Not sure if this is needed
    // static getInstance() {
    //     if (!AlmacenAPI.instance) {
    //         AlmacenAPI.instance = new PrivateAlmacenAPI();
    //     }
    //     return AlmacenAPI.instance;
    // }
}

module.exports = AlmacenAPI;
