import React from 'react';

// Context
import {UsuarioContext, UsuarioDispatchContext} from "../../context/UsuarioProvider";

// Router
import {Navigate, NavLink} from 'react-router-dom';

// MUI
import {Button, FormControl, Select, InputLabel, MenuItem, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function ListaDeAgendadosPagina() {
    const usuario = React.useContext(UsuarioContext);
    const setUsuario = React.useContext(UsuarioDispatchContext);

    const [ barrio, setBarrio ] = React.useState('');

    return (
        <div>

                <Box sx={{ minWidth: 300, maxWidth:350, marginRight:'auto', marginLeft:'auto' }}>
                    <Card variant="outlined">
                        <CardContent>
                            {!(usuario && usuario.usuario_id) && (
                                <>
                                    <FormControl fullWidth>
                                        <NavLink to="/misionero_ingreso"><Button variant="contained">Menu Principal</Button></NavLink><br /><br />
                                        <strong>Martes, 17 de Septiembre</strong><br />
                                        <br />
                                        <strong>06:00 AM:</strong><br />
                                        Matt Handy - Barrio Costa Bonita<br />
                                        Victor Cano - Barrio Floresta<br />
                                        Antonio Velazquez - Barrio Villa Anita<br />
                                        <br />
                                        <strong>07:00 AM:</strong><br />
                                        Rodrigo Peralta - Barrio Villa Elisa<br />
                                        Edgar Meza - Barrio Pai Ñu<br />
                                        Gabriel Garcia - Barrio Leopardi<br />
                                        
                                    </FormControl>
                                </>
                            )}
                            {usuario && usuario.usuario_id && (
                                <>
                                    <Navigate to="/" />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Box>
        </div>
    )
}

export default ListaDeAgendadosPagina;