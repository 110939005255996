import React from 'react';

// Context
import {UsuarioContext, UsuarioDispatchContext} from "../../context/UsuarioProvider";

// Router
import {Navigate, NavLink} from 'react-router-dom';

// MUI
import {Button, FormControl, Select, InputLabel, MenuItem, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function RecepcionDeMercaderiaPagina() {
    const usuario = React.useContext(UsuarioContext);
    const setUsuario = React.useContext(UsuarioDispatchContext);

    const [ barrio, setBarrio ] = React.useState('');

    return (
        <div>

                <Box sx={{ minWidth: 300, maxWidth:350, marginRight:'auto', marginLeft:'auto' }}>
                    <Card variant="outlined">
                        <CardContent>
                            {!(usuario && usuario.usuario_id) && (
                                <>
                                    <FormControl fullWidth>
                                        <NavLink to="/misionero_ingreso"><Button variant="contained">Menu Principal</Button></NavLink><br /><br />
                                        <TextField label="Fecha" variant="outlined" value={"11 Sep 2024"} /><br />
                                        <TextField label="Producto" variant="outlined" value={"Dulce de Leche"} /><br />
                                        <TextField label="Tamaño del Fardo/Caja" variant="outlined" value={"Caja de 24 Unidades"} /><br />
                                        <TextField label="Cantidad de Fardos/Cajas" variant="outlined" value={"75"} /><br />
                                        <Button variant="contained">Guardar</Button>
                                    </FormControl>
                                </>
                            )}
                            {usuario && usuario.usuario_id && (
                                <>
                                    <Navigate to="/" />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Box>
        </div>
    )
}

export default RecepcionDeMercaderiaPagina;