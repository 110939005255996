import React from 'react';

// Context
import {UsuarioContext, UsuarioDispatchContext} from "../../context/UsuarioProvider";

// Router
import {Navigate} from 'react-router-dom';

// MUI
import {Button, FormControl, Select, InputLabel, MenuItem, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function BarrioIngresoPagina() {
    const usuario = React.useContext(UsuarioContext);
    const setUsuario = React.useContext(UsuarioDispatchContext);

    const [ barrio, setBarrio ] = React.useState('');

    return (
        <div>

                <Box sx={{ minWidth: 300, maxWidth:350, marginRight:'auto', marginLeft:'auto' }}>
                    <Card variant="outlined">
                        <CardContent>
                            {!(usuario && usuario.usuario_id) && (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel id="barrio_label">Barrio</InputLabel>
                                        <Select
                                            labelId="barrio_label"
                                            id="barrio-select"
                                            value={barrio}
                                            label="Barrio"
                                            onChange={(e) => {setBarrio(e.target.value);}}
                                        >
                                            <MenuItem value={1}>Costa Bonita</MenuItem>
                                            <MenuItem value={2}>Leopardi</MenuItem>
                                            <MenuItem value={3}>Villa Elisa</MenuItem>
                                        </Select><br />
                                        <TextField
                                            id="contrasena-input"
                                            label="Contraseña"
                                            type="password"
                                        /><br />
                                        <Button variant="contained" onClick={() => {setUsuario({usuario_id:2, nombre: "Matt Handy", barrio: "Costa Bonita"});}}>INGRESAR</Button><br />
                                    </FormControl>
                                </>
                            )}
                            {usuario && usuario.usuario_id && (
                                <>
                                    <Navigate to="/" />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Box>
        </div>
    )
}

export default BarrioIngresoPagina;