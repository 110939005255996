import React from 'react';

// Context
import {UsuarioContext, UsuarioDispatchContext} from "../../context/UsuarioProvider";

// Router
import {Navigate, NavLink} from 'react-router-dom';

// MUI
import {Button, FormControl, Select, InputLabel, MenuItem, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function MisioneroIngresoPagina() {
    const usuario = React.useContext(UsuarioContext);
    const setUsuario = React.useContext(UsuarioDispatchContext);

    const [ barrio, setBarrio ] = React.useState('');

    return (
        <div>

                <Box sx={{ minWidth: 300, maxWidth:350, marginRight:'auto', marginLeft:'auto' }}>
                    <Card variant="outlined">
                        <CardContent>
                            {!(usuario && usuario.usuario_id) && (
                                <>
                                    <FormControl fullWidth>

                                        <NavLink to="/lista_de_agendados"><Button variant="contained">Agendados del Dia</Button></NavLink><br />
                                        <NavLink to="/inventario"><Button variant="contained">Inventario</Button></NavLink><br />
                                        <NavLink to="/recepcion_de_mercaderia"><Button variant="contained">Recepción de Mercadería</Button></NavLink><br />
                                    </FormControl>
                                </>
                            )}
                            {usuario && usuario.usuario_id && (
                                <>
                                    <Navigate to="/" />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Box>
        </div>
    )
}

export default MisioneroIngresoPagina;