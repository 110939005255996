import React from 'react';

// LIB
import AlmacenAPI from '../../lib/AlmacenAPI'

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const error_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #900',
    boxShadow: 24,
    p: 4,
};
const success_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 275,
    bgcolor: 'background.paper',
    border: '2px solid #090',
    boxShadow: 24,
    p: 4,
};


function AgendarAdminPagina() {
    const [ usuario, setUsuario ] = React.useState({});
    const [ datos_cupos_disponibles, setDatosCuposDisponibles ] = React.useState({});
    const [ pedido_creado_y_aprobado, setPedidoCreadoYAprobado ] = React.useState();
    const [ fecha_seleccionada, setFechaSeleccionada ] = React.useState('');
    const [ hora_seleccionada, setHoraSeleccionada ] = React.useState('');
    const [ nombre, setNombre ] = React.useState('');
    const [ retira_otra_persona, setRetiraOtraPersona ] = React.useState('no');
    const [ otra_persona_nombre, setOtraPersonaNombre ] = React.useState('');
    const [ otra_persona_cedula, setOtraPersonaCedula ] = React.useState('');
    const [ error_modal_open, setErrorModalOpen ] = React.useState(false);
    const [ errores, setErrores ] = React.useState([]);
    const [ success_modal_open, setSuccessModalOpen ] = React.useState(false);
    const [ barrio, setBarrio ] = React.useState(0);
    const [ barrios, setBarrios ] = React.useState([]);


    React.useEffect(() => {
        // @todo Send API Query To Get Data
        setDatosCuposDisponibles({ });
        setUsuario({user_id:1, nombre:"Matt", barrio:"Costa Bonita"});

        //@todo Send Barrio Request
        AlmacenAPI.sendRequest("barrios/get", {})
            .then((data) => {
                setBarrios(data);
            })
    }, [])

    const getTableBackgroundColor = (index) => {
        if(index % 2 !== 0) {
            return "#9cb3e7";
        } else {
            return "#dedede";
        }
    }
    const agendarCita = () => {
        let errores = [];
        if(!barrio || barrio === 0) {
            errores.push('Falta el barrio.');
        }
        if(fecha_seleccionada.trim() === '') {
            errores.push('Falta la fecha.');
        }
        if(nombre.trim() === '') {
            errores.push('Falta el nombre del hermano o hermana.');
        }
        if(errores.length > 0) {
            setErrores(errores);
            setErrorModalOpen(true);
        } else {
            // @todo Send API Call
            AlmacenAPI.sendRequest("citas/create", {
                cita: {
                    barrio_id: barrio,
                    fecha: fecha_seleccionada,
                    nombre: nombre
                }
            })
                .then((data) => {
                    console.log(data);
                })
            setSuccessModalOpen(true);
        }

    }
    const getEnlaceParaWhatsApp = () => {
        const mensaje =
            `Su cita para retirar el pedido para *${nombre}* del almacén del obispo será el:

*${fecha_seleccionada}*
*a las ${hora_seleccionada}hs*

Le recordamos que debe *llegar a la hora indicada para no perder el turno*.

Tambien debe llevar:
   - Su cédula de identidad
   - Bolsas en que cargar la mercadería

*Ubicación del almacén:* https://maps.app.goo.gl/3zw5BsSDb9x1XiEL7
`
        return('https://wa.me/?text=' + encodeURI(mensaje));
    }
    const volverAAgendarOtroPedido = () => {
        setPedidoCreadoYAprobado();
        setFechaSeleccionada('');
        setHoraSeleccionada('');
        setNombre('');
        setRetiraOtraPersona('no');
        setOtraPersonaNombre('');
        setOtraPersonaCedula('');
        setErrorModalOpen(false);
        setErrores([]);
        setSuccessModalOpen(false);
        setBarrio(0);
    }

    return (
        <div>
            {
                !usuario.user_id
                    ?
                    <>
                        <br />
                        <TextField label="Barrio" variant="outlined" /><br />
                        <br />
                        <Button variant="contained">Ingresar</Button>

                    </>
                    :
                    <>
                        <div style={{maxWidth:400, marginLeft:'auto', marginRight:'auto', paddingTop:10}}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{textAlign: 'center', fontWeight:'bold', fontSize:'16pt'}}>Agendar Cita Para Retirar Pedido</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key="pedido_creado_y_aprobado"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                {barrios.length > 0 && (
                                                    <div>
                                                        <strong>Información del Pedido:</strong><br /><br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="barrio_label">Barrio:</InputLabel>
                                                            <Select
                                                                labelId="barrio_label"
                                                                id="barrio-select"
                                                                value={barrio}
                                                                label="Barrio"
                                                                onChange={(e) => {setBarrio(e.target.value);}}
                                                            >
                                                                {barrios.map((barrio_item) => {
                                                                    return (
                                                                        <MenuItem value={barrio_item.barrio_id}>{barrio_item.nombre}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select><br />
                                                            <TextField
                                                                required
                                                                id="fecha_seleccionada"
                                                                label="Fecha (dd/mm/aaaa)"
                                                                value={fecha_seleccionada}
                                                                onChange={(e) => {setFechaSeleccionada(e.target.value)}}
                                                            /><br />
                                                            <TextField
                                                                required
                                                                id="nombre_del_hermano"
                                                                label="Nombre del Hermano o Hermana"
                                                                value={nombre}
                                                                onChange={(e) => {setNombre(e.target.value)}}
                                                            /><br />
                                                            <Button variant="contained" onClick={() => {agendarCita();}}>Agendar Cita</Button><br />
                                                        </FormControl>
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
            }
            <Modal
                open={error_modal_open}
                onClose={() => setErrorModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={error_style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#900'}}>
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ul>
                            {errores.map((error) => {
                                return (
                                    <li>{error}</li>
                                );
                            })}
                        </ul>
                        <div style={{textAlign:'center'}}>
                            <Button variant="contained" onClick={() => {setErrorModalOpen(false);}}>Volver para Corregir los Errores</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={success_modal_open}
                onClose={() => setSuccessModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={success_style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#090'}}>
                        ¡Éxito!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {/*Ha agendado la cita con éxito. Por favor, comparta con el hermano o la hermana, o la persona que retirará el pedido, la fecha y la hora de la cita para retirar. Recuérdele que deben salir temprano y tomar en cuenta el tiempo de transporte para poder llegar para la hora indicada.<br /><br />*/}
                        Ha agendado la cita con éxito. <br /><br />
                        <strong>Fecha:</strong> {fecha_seleccionada}<br />
                        {/*<strong>Hora:</strong> {hora_seleccionada}hs<br />*/}
                        <strong>Nombre:</strong> {nombre}<br />
                        <div style={{textAlign:'center'}}>
                            <a href={getEnlaceParaWhatsApp()} target="_blank"><Button variant="contained">Compartir Detalles por WhatsApp</Button></a>
                        </div>
                        {retira_otra_persona === 'si' && (
                            <>
                                <br />
                                <div style={{textAlign:'center',fontWeight:'bold'}}>El pedido será retirado por otra persona:</div>
                                <strong>Nombre:</strong> {otra_persona_nombre}<br />
                                <strong>Número de C.I.:</strong> {otra_persona_cedula}<br />
                            </>
                        )}
                        <br />
                        <div style={{textAlign:'center'}}>
                            <Button variant="contained" onClick={volverAAgendarOtroPedido}>Volver a Agendar Otro Pedido</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default AgendarAdminPagina;
