import React from 'react';

// Router
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

// Context
import { UsuarioProvider } from "./context/UsuarioProvider";

// Image
import background from './fondo.jpg';
// CSS
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Pages
import PaginaPrincipal from './paginas/PaginaPrincipal/';
import AgendarPagina from './paginas/AgendarPagina/'
import AgendarAdminPagina from './paginas/AgendarAdminPagina/'
import BarrioIngresoPagina from './paginas/BarrioIngresoPagina/'
import MisioneroIngresoPagina from './paginas/MisioneroIngresoPagina/'
import ListaDeAgendadosPagina from './paginas/ListaDeAgendadosPagina/'
import InventarioPagina from './paginas/InventarioPagina/'
import RecepcionDeMercaderiaPagina from './paginas/RecepcionDeMercaderiaPagina/'

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Link} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';


function App() {


    React.useEffect(() => {

    }, [])


    return (
        <div className="App" style={{height:"100%", backgroundSize:"cover", backgroundAttachment: 'fixed', backgroundPositionX: 'center', backgroundRepeat: "no-repeat", backgroundImage:`url(${background})`}}>
            <BrowserRouter>
                <UsuarioProvider>
                    <NavLink to="/"><Button startIcon={<HomeIcon />}>Pagina Principal</Button></NavLink>
                    <Routes>
                        <Route path="*" element={<PaginaPrincipal />} />
                        <Route path="/agendar" element={<AgendarPagina />} />
                        <Route path="/agendar_admin" element={<AgendarAdminPagina />} />
                        <Route path="/barrio_ingreso" element={<BarrioIngresoPagina />} />
                        <Route path="/misionero_ingreso" element={<MisioneroIngresoPagina />} />
                        <Route path="/lista_de_agendados" element={<ListaDeAgendadosPagina />} />
                        <Route path="/inventario" element={<InventarioPagina />} />
                        <Route path="/recepcion_de_mercaderia" element={<RecepcionDeMercaderiaPagina />} />
                    </Routes>
                </UsuarioProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
