import React from 'react';

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const error_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #900',
    boxShadow: 24,
    p: 4,
};
const success_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 275,
    bgcolor: 'background.paper',
    border: '2px solid #090',
    boxShadow: 24,
    p: 4,
};


function AgendarPagina() {
    const [ usuario, setUsuario ] = React.useState({});
    const [ datos_cupos_disponibles, setDatosCuposDisponibles ] = React.useState({});
    const [ pedido_creado_y_aprobado, setPedidoCreadoYAprobado ] = React.useState();
    const [ fecha_seleccionada, setFechaSeleccionada ] = React.useState('');
    const [ hora_seleccionada, setHoraSeleccionada ] = React.useState('');
    const [ nombre, setNombre ] = React.useState('');
    const [ retira_otra_persona, setRetiraOtraPersona ] = React.useState('no');
    const [ otra_persona_nombre, setOtraPersonaNombre ] = React.useState('');
    const [ otra_persona_cedula, setOtraPersonaCedula ] = React.useState('');
    const [ error_modal_open, setErrorModalOpen ] = React.useState(false);
    const [ errores, setErrores ] = React.useState([]);
    const [ success_modal_open, setSuccessModalOpen ] = React.useState(false);


    React.useEffect(() => {
        // @todo Send API Query To Get Data
        setDatosCuposDisponibles({
            cupos_permitidos_barrio: 10,
            fechas: [
                {
                    fecha_corta: '2024-07-23',
                    fecha_larga: 'Martes 17 de Septiembre',
                    cupos_usados_total: 80,
                    cupos_usados_barrio: 7,
                    cupos_permitidos_total: 80,
                    cupos_permitidos_barrio: 10,
                    horas: [
                        {
                            hora_corta: '06:00',
                            hora_larga: '06:00hs a 07:00hs',
                            cupos_usados_total: 7,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '07:00',
                            hora_larga: '07:00hs a 08:00hs',
                            cupos_usados_total: 5,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '08:00',
                            hora_larga: '08:00hs a 09:00hs',
                            cupos_usados_total: 9,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '09:00',
                            hora_larga: '09:00hs a 09:30hs',
                            cupos_usados_total: 2,
                            cupos_permitidos_total: 11
                        },
                    ],
                    pedidos_agendados_barrio: [
                        {
                            pedido_id: 1,
                            nombre: 'Hno Eladio',
                        },
                        {
                            pedido_id: 1,
                            nombre: 'Hna Fulana',
                        },
                    ]
                },
                {
                    fecha_corta: '2024-07-25',
                    fecha_larga: 'Jueves 19 de Septiembre',
                    cupos_usados_total: 10,
                    cupos_usados_barrio: 2,
                    cupos_permitidos_total: 80,
                    cupos_permitidos_barrio: 10,
                    horas: [
                        {
                            hora_corta: '06:00',
                            hora_larga: '06:00hs a 07:00hs',
                            cupos_usados_total: 5,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '07:00',
                            hora_larga: '07:00hs a 08:00hs',
                            cupos_usados_total: 0,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '08:00',
                            hora_larga: '08:00hs a 09:00hs',
                            cupos_usados_total: 5,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '09:00',
                            hora_larga: '09:00hs a 09:30hs',
                            cupos_usados_total: 0,
                            cupos_permitidos_total: 11
                        },
                    ],
                    pedidos_agendados_barrio: []
                },
                {
                    fecha_corta: '2024-07-30',
                    fecha_larga: 'Martes 24 de Septiembre',
                    cupos_usados_total: 56,
                    cupos_usados_barrio: 10,
                    cupos_permitidos_total: 80,
                    cupos_permitidos_barrio: 10,
                    horas: [
                        {
                            hora_corta: '06:00',
                            hora_larga: '06:00hs a 07:00hs',
                            cupos_usados_total: 23,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '07:00',
                            hora_larga: '07:00hs a 08:00hs',
                            cupos_usados_total: 23,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '08:00',
                            hora_larga: '08:00hs a 09:00hs',
                            cupos_usados_total: 10,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '09:00',
                            hora_larga: '09:00hs a 09:30hs',
                            cupos_usados_total: 0,
                            cupos_permitidos_total: 11
                        },
                    ],
                    pedidos_agendados_barrio: [
                        {
                            pedido_id: 1,
                            nombre: 'Hno Jose',
                        },
                    ]
                },
                {
                    fecha_corta: '2024-08-01',
                    fecha_larga: 'Jueves 26 de Septiembre',
                    cupos_usados_total: 50,
                    cupos_usados_barrio: 0,
                    cupos_permitidos_total: 80,
                    cupos_permitidos_barrio: 10,
                    horas: [
                        {
                            hora_corta: '06:00',
                            hora_larga: '06:00hs a 07:00hs',
                            cupos_usados_total: 23,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '07:00',
                            hora_larga: '07:00hs a 08:00hs',
                            cupos_usados_total: 3,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '08:00',
                            hora_larga: '08:00hs a 09:00hs',
                            cupos_usados_total: 23,
                            cupos_permitidos_total: 23
                        },
                        {
                            hora_corta: '09:00',
                            hora_larga: '09:00hs a 09:30hs',
                            cupos_usados_total: 1,
                            cupos_permitidos_total: 11
                        },
                    ],
                    pedidos_agendados_barrio: []
                },
            ]
        });
        setUsuario({user_id:1, nombre:"Matt", barrio:"Costa Bonita"});
    }, [])

    const getFechaItemByFechaCorta = (fecha_corta) => {
        if(datos_cupos_disponibles && datos_cupos_disponibles.fechas) {
            const index = datos_cupos_disponibles.fechas.findIndex((item) => {
                return (item.fecha_corta === fecha_corta);
            })
            if (index === -1) {
                return false;
            } else {
                return datos_cupos_disponibles.fechas[index];
            }
        }
        return false;
    }
    const getFechaLargaByFechaCorta = (fecha_corta) => {
        const fecha_item = getFechaItemByFechaCorta(fecha_corta);
        if(fecha_item && fecha_item.fecha_larga) {
            return fecha_item.fecha_larga;
        } else {
            return "Error - La fecha no se encontró.";
        }
    }
    const getHorasByFechaCorta = (fecha_corta) => {
        const fecha_item = getFechaItemByFechaCorta(fecha_corta);
        if(fecha_item && fecha_item.horas) {
            return fecha_item.horas;
        } else {
            return [];
        }
    }
    const getHoraItemByFechaCortaYHoraCorta = (fecha_corta, hora_corta) => {
        const fecha_item = getFechaItemByFechaCorta(fecha_corta);
        if(fecha_item && fecha_item.horas) {
            const hora_index = fecha_item.horas.findIndex((hora_item) => {
                return (hora_item.hora_corta === hora_corta);
            });
            if(hora_index === -1) {
                return false;
            }
            return fecha_item.horas[hora_index];
        } else {
            return {};
        }
    }
    const getHoraLargaByFechaCortaYHoraCorta = (fecha_corta, hora_corta) => {
        const hora_item = getHoraItemByFechaCortaYHoraCorta(fecha_corta, hora_corta);
        if(hora_item && hora_item.hora_larga) {
            return hora_item.hora_larga
        } else {
            return "Error: La hora no se encontró."
        }
    }
    const getTableBackgroundColor = (index) => {
        if(index % 2 !== 0) {
            return "#9cb3e7";
        } else {
            return "#dedede";
        }
    }
    const agendarCita = () => {
        let errores = [];
        if(nombre.trim() === '') {
            errores.push('Falta el nombre del hermano o hermana en cuyo nombre se creó el pedido.');
        }
        if(retira_otra_persona === 'si') {
            if(otra_persona_nombre.trim() === '') {
                errores.push('Falta el nombre de la persona que retirará el pedido.')
            }
            if(otra_persona_cedula.trim() === '') {
                errores.push('Falta el número de cédula de la persona que retirará el pedido.')
            }
        }
        if(errores.length > 0) {
            setErrores(errores);
            setErrorModalOpen(true);
        } else {
            setSuccessModalOpen(true);
        }

    }
    const getEnlaceParaWhatsApp = () => {
        const mensaje =
            `Su cita para retirar el pedido para *${nombre}* del almacén del obispo será el:

*${getFechaLargaByFechaCorta(fecha_seleccionada)}*
*a las ${hora_seleccionada}hs*

Le recordamos que debe *llegar a la hora indicada para no perder el turno*.

Tambien debe llevar:
   - Su cédula de identidad
   - Bolsas en que cargar la mercadería

*Ubicación del almacén:* https://maps.app.goo.gl/3zw5BsSDb9x1XiEL7
`
        return('https://wa.me/?text=' + encodeURI(mensaje));
    }
    const volverAAgendarOtroPedido = () => {
        setPedidoCreadoYAprobado();
        setFechaSeleccionada('');
        setHoraSeleccionada('');
        setNombre('');
        setRetiraOtraPersona('no');
        setOtraPersonaNombre('');
        setOtraPersonaCedula('');
        setErrorModalOpen(false);
        setErrores([]);
        setSuccessModalOpen(false);
    }

    const PasoUno = () => {
        return (
            <TableRow
                key="pedido_creado_y_aprobado"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="center">
                    <strong>Paso 1: Crear y Aprobar Pedido:</strong><br />
                    {pedido_creado_y_aprobado !== true && pedido_creado_y_aprobado !== false && (
                        <>
                            Antes de ser agendado, el pedido debe ser creado en el sistema de pedidos del almacén del obispo, y debe ser aprobado por el obispo.<br />
                            <br />
                            <strong>El Obispo ya Aprobó el Pedido en el Sistema?</strong><br />
                            <RadioGroup row sx={{justifyContent:'center'}}>
                                <div style={{textAlign:'center'}}>
                                    <FormControlLabel value={true} control={<Radio />} label="Si" onClick={(e) => setPedidoCreadoYAprobado(e.target.value === "true")} />
                                    <FormControlLabel value={false} control={<Radio />} label="No" onClick={(e) => setPedidoCreadoYAprobado(e.target.value === "true")} />
                                </div>
                            </RadioGroup>
                        </>
                    )}
                    {pedido_creado_y_aprobado === true && (
                        <strong style={{color:"#009900"}}>
                            El Pedido ha sido creado y aprobado en el sistema.
                        </strong>
                    )}
                    {pedido_creado_y_aprobado === false && (
                        <div>
                            <div style={{color:"#990000", fontWeight:'bold'}}>
                                El pedido debe ser creado en el sistema y luego aprobado por el obispo antes de poder agendarlo. Una vez que el pedido esté aprobado, usted debe volver para solicitar el agendamiento.<br />
                            </div>
                            <br />
                            <Button variant="contained" onClick={(e) => {volverAAgendarOtroPedido()}}>Agendar Otro Pedido</Button>
                        </div>
                    )}
                </TableCell>
            </TableRow>
        );
    }
    const PasoDos = () => {
        return (
            <TableRow
                key="pedido_creado_y_aprobado"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    <Button size="small" startIcon={<KeyboardBackspaceIcon />} onClick={() => {setPedidoCreadoYAprobado('');}}>Volver Atras</Button><br />
                    <div style={{textAlign:'center'}}>
                        <strong>Paso 2: Seleccionar Fecha:</strong><br />
                        {fecha_seleccionada === '' && (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{backgroundColor: "#1556ea"}}>
                                            <TableCell style={{color:"#ffffff"}}>Fecha</TableCell>
                                            <TableCell style={{color:"#ffffff"}}>Cupos</TableCell>
                                            <TableCell style={{color:"#ffffff"}}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {datos_cupos_disponibles.fechas.map((fecha_item, index) => {
                                            return (
                                                <>
                                                    <TableRow style={{backgroundColor:getTableBackgroundColor(index)}}>
                                                        <TableCell><strong>{fecha_item.fecha_larga}</strong></TableCell>
                                                        <TableCell align="center">
                                                            {fecha_item.cupos_permitidos_total - fecha_item.cupos_usados_total >= 0 ? fecha_item.cupos_permitidos_total - fecha_item.cupos_usados_total : 0}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {fecha_item.cupos_usados_total < fecha_item.cupos_permitidos_total && fecha_item.cupos_usados_barrio < fecha_item.cupos_permitidos_barrio && (
                                                                <Button variant="contained" onClick={(e) => {setFechaSeleccionada(fecha_item.fecha_corta)}}>ELEGIR</Button>
                                                            )}
                                                            {!(fecha_item.cupos_usados_total < fecha_item.cupos_permitidos_total && fecha_item.cupos_usados_barrio < fecha_item.cupos_permitidos_barrio) && (
                                                                <Button variant="contained" disabled>ELEGIR</Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    {(fecha_item.cupos_usados_total >= fecha_item.cupos_permitidos_total && (
                                                        <TableRow style={{backgroundColor:getTableBackgroundColor(index)}}>
                                                            <TableCell colSpan={3} align="center" style={{paddingTop:0, color:"#990000", fontWeight:"bold"}}>Ya no hay cupos disponibles para esta fecha.</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {(fecha_item.cupos_usados_barrio >= fecha_item.cupos_permitidos_barrio && fecha_item.cupos_usados_total < fecha_item.cupos_permitidos_total && (
                                                        <TableRow style={{backgroundColor:getTableBackgroundColor(index)}}>
                                                            <TableCell colSpan={3} align="center" style={{paddingTop:0, color:"#990000", fontWeight:"bold"}}>Su barrio ya tiene {fecha_item.cupos_usados_barrio} pedidos agendados para este dia. Solo se permite {datos_cupos_disponibles.cupos_permitidos_barrio} pedidos por dia por barrio. Por favor, elija otra fecha.</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {/**/}
                        {fecha_seleccionada !== '' && (
                            <>
                                <strong style={{color:'#009900'}}>{getFechaLargaByFechaCorta(fecha_seleccionada)}</strong><br />
                                <Button variant="contained" onClick={(e) => {setFechaSeleccionada(''); setHoraSeleccionada('');}}>Cambiar Fecha</Button>
                            </>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    const PasoTres = () => {
        return (
            <TableRow
                key="pedido_creado_y_aprobado"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    <Button size="small" startIcon={<KeyboardBackspaceIcon />} onClick={() => {setFechaSeleccionada('');}}>Volver Atras</Button><br />
                    <div style={{textAlign:'center'}}>
                        <strong>Paso 3: Seleccionar Hora:</strong><br />
                        {hora_seleccionada === '' && (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{backgroundColor: "#1556ea"}}>
                                            <TableCell align="center" style={{color:"#ffffff"}}>Hora</TableCell>
                                            <TableCell align="center" style={{color:"#ffffff"}}>Cupos<br />Disponibles</TableCell>
                                            <TableCell align="center" style={{color:"#ffffff"}}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getHorasByFechaCorta(fecha_seleccionada).map((hora_item, index) => {
                                            return (
                                                <>
                                                    <TableRow style={{backgroundColor:getTableBackgroundColor(index)}}>
                                                        <TableCell align="center"><strong>{hora_item.hora_corta} hs</strong></TableCell>
                                                        <TableCell align="center">
                                                            {hora_item.cupos_permitidos_total - hora_item.cupos_usados_total >= 0 ? hora_item.cupos_permitidos_total - hora_item.cupos_usados_total : 0}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {hora_item.cupos_usados_total < hora_item.cupos_permitidos_total && (
                                                                <Button variant="contained" onClick={(e) => {setHoraSeleccionada(hora_item.hora_corta)}}>ELEGIR</Button>
                                                            )}
                                                            {!(hora_item.cupos_usados_total < hora_item.cupos_permitidos_total) && (
                                                                <Button variant="contained" disabled>ELEGIR</Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    {(hora_item.cupos_usados_total >= hora_item.cupos_permitidos_total && (
                                                        <TableRow style={{backgroundColor:getTableBackgroundColor(index)}}>
                                                            <TableCell colSpan={3} align="center" style={{paddingTop:0, color:"#990000", fontWeight:"bold"}}>Ya no hay cupos disponibles para esta hora.</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {hora_seleccionada !== '' && (
                            <>
                                <strong style={{color:'#009900'}}>{hora_seleccionada}</strong><br />
                                <Button variant="contained" onClick={(e) => {setHoraSeleccionada('');}}>Cambiar Hora</Button>
                            </>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    const PasoCuatro = () => {
        return (
            <></>
        );
    }

    return (
        <div>
            {
                !usuario.user_id
                    ?
                    <>
                        <br />
                        <TextField label="Barrio" variant="outlined" /><br />
                        <br />
                        <Button variant="contained">Ingresar</Button>

                    </>
                    :
                    <>
                        <div style={{maxWidth:400, marginLeft:'auto', marginRight:'auto', paddingTop:10}}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{textAlign: 'center', fontWeight:'bold', fontSize:'16pt'}}>Agendar Cita Para Retirar Pedido</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pedido_creado_y_aprobado !== true && (
                                            <PasoUno />
                                        )}
                                        {pedido_creado_y_aprobado === true && !fecha_seleccionada && !hora_seleccionada && (
                                            <PasoDos />
                                        )}
                                        {pedido_creado_y_aprobado === true && fecha_seleccionada !== '' && !hora_seleccionada && (
                                            <PasoTres />
                                        )}
                                        {pedido_creado_y_aprobado === true && fecha_seleccionada !== '' && hora_seleccionada && (
                                            <TableRow
                                                key="pedido_creado_y_aprobado"
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <Button size="small" startIcon={<KeyboardBackspaceIcon />} onClick={() => {setHoraSeleccionada('');}}>Volver Atras</Button><br />
                                                    <div style={{textAlign:'center'}}>
                                                        <strong>Paso 4: Información del Pedido:</strong><br />
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                            }}
                                                            noValidate
                                                            autoComplete="off"
                                                        >
                                                            {/*<TextField*/}
                                                            {/*    disabled*/}
                                                            {/*    id="barrio"*/}
                                                            {/*    label="Barrio"*/}
                                                            {/*    value={usuario.barrio}*/}
                                                            {/*    style={{width:300}}*/}
                                                            {/*/>*/}
                                                            <TextField
                                                                required
                                                                id="nombre_del_hermano"
                                                                label="Nombre del Hermano o Hermana"
                                                                value={nombre}
                                                                style={{width:300}}
                                                                onChange={(e) => {setNombre(e.target.value)}}
                                                            /><br />
                                                            <strong>El pedido será retirado por otra persona?</strong><br />
                                                            <RadioGroup
                                                                row
                                                                sx={{justifyContent:'center'}}
                                                                value={retira_otra_persona}
                                                                onChange={(e) => setRetiraOtraPersona(e.target.value)}
                                                            >
                                                                <div style={{textAlign:'center'}}>
                                                                    <FormControlLabel value="si" control={<Radio />} label="Si" />
                                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                                </div>
                                                            </RadioGroup><br />
                                                            {retira_otra_persona === 'si' && (
                                                                <>
                                                                    <strong>Por favor indique el nombre y numero de cedula de la persona que retirará el pedido. La persona solo podrá retirar un pedido del almacén en esa fecha.</strong><br />
                                                                    <TextField
                                                                        required
                                                                        id="otra_persona_nombre"
                                                                        label="Nombre de la Otra Persona"
                                                                        value={otra_persona_nombre}
                                                                        style={{width:300}}
                                                                        onChange={(e) => {setOtraPersonaNombre(e.target.value)}}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="otra_persona_cedula"
                                                                        label="Número de C.I. de la Otra Persona"
                                                                        value={otra_persona_cedula}
                                                                        style={{width:300}}
                                                                        onChange={(e) => {setOtraPersonaCedula(e.target.value)}}
                                                                    />
                                                                </>
                                                            )}
                                                            <Button variant="contained" onClick={(e) => {agendarCita();}}>Agendar Cita</Button>
                                                        </Box>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
            }
            <Modal
                open={error_modal_open}
                onClose={() => setErrorModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={error_style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#900'}}>
                        Error
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ul>
                            {errores.map((error) => {
                                return (
                                    <li>{error}</li>
                                );
                            })}
                        </ul>
                        <div style={{textAlign:'center'}}>
                            <Button variant="contained" onClick={() => {setErrorModalOpen(false);}}>Volver para Corregir los Errores</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={success_modal_open}
                onClose={() => setSuccessModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={success_style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#090'}}>
                        ¡Éxito!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Ha agendado la cita con éxito. Por favor, comparta con el hermano o la hermana, o la persona que retirará el pedido, la fecha y la hora de la cita para retirar. Recuérdele que deben salir temprano y tomar en cuenta el tiempo de transporte para poder llegar para la hora indicada.<br /><br />
                        <strong>Fecha:</strong> {getFechaLargaByFechaCorta(fecha_seleccionada)}<br />
                        <strong>Hora:</strong> {hora_seleccionada}hs<br />
                        <strong>Nombre:</strong> {nombre}<br />
                        <div style={{textAlign:'center'}}>
                            <a href={getEnlaceParaWhatsApp()} target="_blank"><Button variant="contained">Compartir Detalles por WhatsApp</Button></a>
                        </div>
                        {retira_otra_persona === 'si' && (
                            <>
                                <br />
                                <div style={{textAlign:'center',fontWeight:'bold'}}>El pedido será retirado por otra persona:</div>
                                <strong>Nombre:</strong> {otra_persona_nombre}<br />
                                <strong>Número de C.I.:</strong> {otra_persona_cedula}<br />
                            </>
                        )}
                        <br />
                        <div style={{textAlign:'center'}}>
                            <Button variant="contained" onClick={volverAAgendarOtroPedido}>Volver a Agendar Otro Pedido</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default AgendarPagina;
