import React from 'react';

// Context
import {UsuarioContext, UsuarioDispatchContext} from "../../context/UsuarioProvider";

// Router
import { NavLink } from 'react-router-dom'

// Lib
import AlmacenAPI from '../../lib/AlmacenAPI'

// MUI
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function PaginaPrincipal() {
    const usuario = React.useContext(UsuarioContext);
    const setUsuario = React.useContext(UsuarioDispatchContext);

    return (
        <div>

            <Box sx={{ minWidth: 300, maxWidth:350, marginRight:'auto', marginLeft:'auto' }}>
                <Card variant="outlined">
                    <CardContent>
                        Bienvenido al sistema de agendamiento del almacén del obispo de Paraguay.<br />
                        <br />
                        {!(usuario && usuario.usuario_id) && (
                            <>
                                <NavLink to="/barrio_ingreso"><Button variant="contained">SOY LIDER DE BARRIO/RAMA</Button></NavLink><br />
                                <br />
                                <NavLink to="/misionero_ingreso"><Button variant="contained">SOY MISIONERO/VOLUNTARIO</Button></NavLink><br />

                            </>
                        )}
                        {usuario && usuario.usuario_id && (
                            <>
                                Bienvenido {usuario.nombre}!<br />
                                <br />
                                {"soybarrio" === "soybarrio" && (
                                    <>
                                        <NavLink to="/agendar"><Button variant="contained">AGENDAR UN PEDIDO</Button></NavLink><br />
                                        <br />
                                        LISTA DE PEDIDOS PARA BARRIO {usuario.barrio}:<br />
                                        <br />
                                    </>
                                )}
                                <Button variant="contained" onClick={() => {setUsuario({});}}>SALIR DEL SISTEMA</Button>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default PaginaPrincipal;